import { Link } from 'react-router-dom'
import TransitionLayout from '@/ui/TransitionLayout'
import EmptyState from '@/ui/EmptyState'
import animation from '@/ui/lotties/Nomad.json'

const CantAccessKnowledgeBase = () => {
  return (
    <TransitionLayout>
      <div className="mobile-layout">
        <EmptyState animation={animation} title="No has activado esta sección">
          <p className="empty-text">
            Activa la <em>integración con documentos</em> desde la página de tu{' '}
            <Link to="/subscriptions">organización</Link> para habilitar la base
            de conocimiento.
          </p>
        </EmptyState>
      </div>
    </TransitionLayout>
  )
}

export default CantAccessKnowledgeBase
