import { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import { GlobalErrorPage } from './pages/GlobalErrorPage'

export const SentryErrorBoundary = observer(
  ({ children }: { children: ReactNode }) => {
    return (
      <Sentry.ErrorBoundary fallback={<GlobalErrorPage />}>
        {children}
      </Sentry.ErrorBoundary>
    )
  },
)
