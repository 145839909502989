import { fetchJSON } from '@/utils/http'
import { API_ROUTE } from '../backend/config'
import { OTPStatusSchema, SignupData, UserPublicFlag } from './types'

// PAYLOAD AUTH ENDPOINTS
// Errors are not controlled by us to we disable the error toast

export const login = async (email: string, password: string) => {
  return await fetchJSON(`${API_ROUTE}/users/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    disableErrorToast: true,
  })
}

export const logout = async () => {
  return await fetchJSON(`${API_ROUTE}/users/logout`, {
    method: 'POST',
    disableErrorToast: true,
  })
}

export const signup = async (signup: SignupData) => {
  return await fetchJSON(`${API_ROUTE}/users/signup`, {
    method: 'POST',
    body: JSON.stringify(signup),
    disableErrorToast: true,
  })
}

export const checkAuth = async () => {
  return await fetchJSON(`${API_ROUTE}/users/me`, {
    disableErrorToast: true,
  })
}

export const forgotPassword = async (email: string) => {
  return await fetchJSON(`${API_ROUTE}/users/forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    disableErrorToast: true,
  })
}

export const resetPassword = async (token: string, password: string) => {
  return await fetchJSON(`${API_ROUTE}/users/reset-password`, {
    method: 'POST',
    body: JSON.stringify({ token, password }),
    disableErrorToast: true,
  })
}

export const verify = async (token: string) => {
  return await fetchJSON(`${API_ROUTE}/users/verify/${token}`, {
    method: 'POST',
    disableErrorToast: true,
  })
}

// CUSTOM AUTH ENDPOINTS

export const getPrefilledUserData = async (token: string) => {
  return await fetchJSON(`${API_ROUTE}/users/prefilled/${token}`, {})
}

export const checkOTPRequirement = async () => {
  const response = await fetchJSON(`${API_ROUTE}/users/otp`, {
    method: 'GET',
  })

  return OTPStatusSchema.parse(response.status)
}

export const requestOTPCode = async () => {
  const response = await fetchJSON(`${API_ROUTE}/users/otp`, {
    method: 'POST',
  })

  return OTPStatusSchema.parse(response.status)
}

export const verifyOTPCode = async (otp: string) => {
  const response = await fetchJSON(`${API_ROUTE}/users/otp/verify`, {
    method: 'POST',
    body: JSON.stringify({ otp }),
  })

  return OTPStatusSchema.parse(response.status)
}

export const updateUserPublicFlags = async (flags: UserPublicFlag[]) => {
  return await fetchJSON(`${API_ROUTE}/users/public-flags`, {
    method: 'PATCH',
    body: JSON.stringify(flags),
  })
}

const GOOGLE_REDIRECT_URL = `${window.location.origin}/auth/google-callback`

export type GoogleOAuthUrlResponse =
  | { authorized: true }
  | { authorized: false; url: string }

export const checkGoogleOAuthStatus = async () => {
  const response = await fetchJSON(
    `${API_ROUTE}/users/google-oauth/start?redirectUrl=${GOOGLE_REDIRECT_URL}`,
  )

  return response as GoogleOAuthUrlResponse
}

export const exchangeGoogleOAuthCredentials = async (code: string) => {
  await fetchJSON(`${API_ROUTE}/users/google-oauth/callback`, {
    method: 'POST',
    body: JSON.stringify({ code, redirectUrl: GOOGLE_REDIRECT_URL }),
  })
}
