import UserMenu from '@/components/auth/UserMenu'
import { observer } from 'mobx-react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import logo from '/images/aikit.svg'
import Link from '@/ui/Link'
import RoundButton from '@/ui/RoundButton'
import { Icon, Icons } from './icon'
import rootStore from '@/app/store'
import cx from 'classnames'

const menu = [
  { to: '/explore', icon: Icons.Explore, text: 'Explora' },
  // { to: '/favorites', icon: Icons.Fav, text: 'Favoritos' },
  // { to: '/assistants', icon: Icons.Chat, text: 'Asistentes' },
  { to: '/use-cases', icon: Icons.Resources, text: 'Casos de uso' },
  { to: '/agents', icon: Icons.Agents, text: 'Agentes' },
]

const secondaryMenu = [
  { to: '/subscriptions', icon: Icons.Group, text: 'Organización' },
  { to: '/knowledge-bases', icon: Icons.Brain, text: 'Base de conocimiento' },
]

type SidebarProps = {
  onClickCollapse: () => void
}

const Sidebar = ({ onClickCollapse }: SidebarProps) => {
  const { auth } = rootStore
  const location = useLocation()

  const isPathActive = (path: string) => {
    if (!path) return false

    if (path.startsWith('/toolsets/')) {
      return location.pathname.includes(path)
    }
    return location.pathname === path
  }

  return (
    <header className="sidebar">
      <div className="sidebar-header">
        <RouterLink className="sidebar-logo" to="/home">
          <img width="96" height="20" src={logo} alt="Logo AI Kit" />
        </RouterLink>
        <RoundButton
          icon={Icons.Menu}
          variant="contrast"
          label="Menu"
          onClick={onClickCollapse}
        />
      </div>
      <div className="sidebar-content">
        <nav className="sidebar-nav">
          <RouterLink className="sidebar-navButton" to="/assistants">
            <Icon name={Icons.Write} />
            <span>Nuevo chat</span>
          </RouterLink>
          {menu.map((item) => (
            <Link
              key={item.to}
              to={item.to}
              className="sidebar-item"
              isActive={isPathActive(item.to)}
              iconName={item.icon}
            >
              {item.text}
            </Link>
          ))}
          <div
            className={cx('sidebar-settings', {
              'is-notAdmin': auth.user?.role !== 'org-manager',
            })}
          >
            <UserMenu />
          </div>
          {auth.user?.role === 'org-manager' &&
            secondaryMenu.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className="sidebar-item"
                isActive={isPathActive(item.to)}
                iconName={item.icon}
              >
                {item.text}
              </Link>
            ))}
        </nav>
      </div>
    </header>
  )
}

export default observer(Sidebar)
