import React from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import Lottie from 'react-lottie-player'
import { Icons, Icon } from './icon'
import CTA from '@/ui/CTA'

interface Props {
  children?: React.ReactNode
  animation?: Record<string, any> | object
  image?: string
  title?: string
  text?: string
  buttonText?: string
  hasButton?: boolean
  icon?: any
  className?: string
  dataCy?: string
  onButtonClick?: () => void
}

const EmptyState = ({
  children,
  animation,
  image,
  icon,
  title,
  text,
  buttonText,
  hasButton,
  className,
  dataCy,
  onButtonClick,
}: Props) => {
  return (
    <div className={cx('empty-state', className)} data-cy={dataCy}>
      {animation ? (
        <div className="empty-animation">
          <Lottie loop animationData={animation} play />
        </div>
      ) : (
        <div className="empty-animation">
          {image ? <img src={image} alt="" /> : null}
        </div>
      )}
      {icon ? <Icon size={48} name={icon} /> : null}
      <div className="empty-info">
        {title ? <p className="empty-title">{title}</p> : null}
        {text ? <p className="empty-text">{text}</p> : children}
      </div>
      {hasButton ? (
        <CTA
          to={onButtonClick ? undefined : '/home'}
          variant="system"
          size="medium"
          iconLeftName={Icons.Refresh}
          onClick={onButtonClick}
        >
          {buttonText || 'Recargar'}
        </CTA>
      ) : null}
    </div>
  )
}

export default observer(EmptyState)
