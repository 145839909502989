import { toast } from 'react-toastify'
import { parseHTTPError } from './error'

// eslint-disable-next-line no-undef
type CustomRequestOptions = RequestInit & {
  disableErrorToast?: boolean
}

export const authFetch = async (
  path: string,
  opts: CustomRequestOptions = {},
) => {
  const response = await fetch(path, {
    credentials: 'include',
    ...opts,
  })

  if (!response.ok) {
    const httpError = await parseHTTPError(response)

    if (opts.disableErrorToast) {
      throw httpError
    }

    for (const error of httpError.cmsErrors) {
      // NOTE: Only toast global errors, not field errors
      if (!error.field) {
        toast.error(error.message)
      }
    }

    throw httpError
  }

  return response
}

export const fetchJSON = async (
  path: string,
  opts: CustomRequestOptions = {},
) => {
  const headers = opts.headers ?? {}
  const response = await authFetch(path, {
    ...opts,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}
