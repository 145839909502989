import { components, paths } from '@/lib/ragatron/schema'
import { triggerBlobDownload } from '@/utils/download'
import createClient from 'openapi-fetch'

export const RAGATRON_API_BASE =
  import.meta.env.VITE_RAGATRON_API_BASE || 'http://127.0.0.1:4000/api/ragatron'

export type KnowledgeBaseModel =
  components['schemas']['KnowledgeBaseConfigResponse']

export type KnowledgeBaseDTO =
  components['schemas']['KnowledgeBaseConfigModelDTO']

export type UpdateKnowledgeBaseModel =
  components['schemas']['UpdateKnowledgeBaseConfig']

export type InternalDocument = components['schemas']['InternalDocumentModel']

export type PaginatedInternalDocuments =
  components['schemas']['PaginatedInternalDocuments']

export type StorageProviderConfig =
  components['schemas']['StorageProviderConfig']

export type OAuthFlowStartResult = components['schemas']['OAuthFlowStartResult']

export const MASKED_GLOBAL_TOOLKIT_ID = 'global'

export class RagatronError extends Error {
  statusCode: number
  detail: string
  extra?: Record<string, any> | null

  constructor(
    statusCode: number,
    detail: string,
    extra?: Record<string, any> | null,
  ) {
    super(detail)

    this.statusCode = statusCode
    this.detail = detail
    this.extra = extra
  }
}

export class KnowledgeBaseAPI {
  client = createClient<paths>({
    baseUrl: RAGATRON_API_BASE,
    credentials: 'include',
    cache: 'no-cache',
  })

  async fetchById(id: string): Promise<KnowledgeBaseModel> {
    const { data, error } = await this.client.GET('/knowledge-base/{kb_id}', {
      params: { path: { kb_id: id } },
    })

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async fetchDocuments(
    id: string,
    limit: number,
    offset: number,
    searchQuery?: string,
  ) {
    const { data, error } = await this.client.GET(
      '/knowledge-base/{kb_id}/documents',
      {
        params: {
          path: { kb_id: id },
          query: { search_query: searchQuery, limit, offset },
        },
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async create(knowledge_base: KnowledgeBaseDTO) {
    const { data, error } = await this.client.POST('/knowledge-base', {
      body: knowledge_base,
    })

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async update(
    id: string,
    updatedFields: UpdateKnowledgeBaseModel,
  ): Promise<KnowledgeBaseDTO> {
    const { data, error } = await this.client.PATCH('/knowledge-base/{kb_id}', {
      params: { path: { kb_id: id } },
      body: updatedFields,
    })

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async delete(id: string) {
    const { error } = await this.client.DELETE('/knowledge-base/{kb_id}', {
      params: { path: { kb_id: id } },
    })

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }
  }

  async uploadFile(kb_id: string, file: File) {
    const formData = new FormData()
    // TODO: Implement base_path to allow folder uploads
    formData.append('base_path', '/')
    formData.append('document', file)

    const { data, error } = await this.client.POST(
      '/knowledge-base/{kb_id}/documents',
      {
        params: { path: { kb_id } },
        // @ts-ignore
        body: formData,
        bodySerializer: (x) => x,
        parseAs: 'text',
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async downloadDocument(
    kb_id: string,
    path_or_id: string,
    displayPath: string,
  ) {
    const { data, error } = await this.client.GET(
      '/knowledge-base/{kb_id}/documents/{path_or_id}',
      {
        params: { path: { kb_id, path_or_id } },
        parseAs: 'blob',
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    if (!data) {
      throw new RagatronError(500, 'No data returned')
    }

    triggerBlobDownload(displayPath, data)
  }

  async deleteDocument(kb_id: string, path_or_id: string) {
    const { error } = await this.client.DELETE(
      '/knowledge-base/{kb_id}/documents/{path_or_id}',
      {
        params: { path: { kb_id, path_or_id } },
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }
  }

  async connectOAuth2(
    provider: string,
    kbId: string,
    redirectURI: string,
  ): Promise<OAuthFlowStartResult> {
    const { data, error } = await this.client.GET(
      '/knowledge-base/{kb_id}/oauth2/start',
      {
        params: {
          path: { kb_id: kbId },
          // @ts-ignore
          query: { storage_provider: provider, redirect_uri: redirectURI },
        },
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }

  async verifyOAuth2(
    provider: string,
    redirectURI: string,
    csrf_token: string,
    query: Record<string, string>,
  ) {
    const { data, error } = await this.client.POST(
      '/knowledge-base/oauth2/{storage_provider}/callback',
      {
        params: {
          // @ts-ignore
          path: { storage_provider: provider },
          query,
        },
        body: {
          redirect_uri: redirectURI,
          csrf_token: csrf_token,
        },
      },
    )

    if (error) {
      throw new RagatronError(error.status_code, error.detail, error.extra)
    }

    return data
  }
}
