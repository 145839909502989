import { Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

interface Props {
  consumedGB: number
  totalGB: number
}

export default function StorageConsumption({ consumedGB, totalGB }: Props) {
  const usedPercentage = Math.ceil((consumedGB / totalGB) * 100)

  return (
    <div>
      <BorderLinearProgress
        sx={{ width: 420 }}
        variant="determinate"
        value={usedPercentage}
      />

      <Typography variant="body2" sx={{ opacity: 0.5, mt: 1 }}>
        Has utilizado {consumedGB} GB de {totalGB} GB disponibles (
        <b>{usedPercentage}%</b>)
      </Typography>
    </div>
  )
}
