import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

interface DriveIntegrationExplanationsProps {
  provider: 'google_drive' | 'onedrive' | 'dropbox' | 'azure_blob_storage'
}

const DriveIntegrationExplanations = ({
  provider,
}: DriveIntegrationExplanationsProps) => {
  return (
    <>
      {provider === 'azure_blob_storage' && (
        <div className="knowledge-base-form-page-info">
          <h3>Gestión manual</h3>{' '}
          <p>
            La gestión manual te permite sincronizar tus documentos fácilmente
            sin tener que integrar AiKit con ningún proveedor de almacenamiento
            externo.
          </p>
          <h4>Proceso de integración</h4>
          <ol>
            <li>
              Accede a la sección de{' '}
              <Link to="/knowledge-bases">bases de conocimiento</Link>.
            </li>
            <li>Sube los documentos que deseas sincronizar.</li>
          </ol>
          <h5>Información importante</h5>
          <ul>
            <li>
              La sincronización puede tardar hasta una hora en tomar efecto.
            </li>
          </ul>
        </div>
      )}

      {provider === 'google_drive' && (
        <div className="knowledge-base-form-page-info">
          <h3>Integración con Google Drive</h3>{' '}
          <p>
            La integración con Google Drive te permite sincronizar
            automáticamente tus documentos con AiKit de forma sencilla.
          </p>
          <h4>Proceso de integración</h4>
          <ol>
            <li>
              Selecciona la cuenta de Google Drive que deseas conectar con
              AiKit.
            </li>
            <li>
              Una vez vinculada tu cuenta, encontrarás una nueva carpeta llamada
              "AiKit" en la raíz de tu Google Drive.
            </li>
            <li>
              Todos los documentos que coloques en la carpeta "AiKit" se
              sincronizarán automáticamente con nuestra plataforma.
            </li>
          </ol>
          <h5>Información importante</h5>
          <ul>
            <li>La sincronización se realiza automáticamente cada hora.</li>
            <li>
              Todos los documentos dentro de la carpeta AiKit serán procesados.
            </li>
            <li>
              Puedes organizar tus documentos en subcarpetas dentro de la
              carpeta AiKit.
            </li>
          </ul>
        </div>
      )}

      {provider === 'onedrive' && (
        <div className="knowledge-base-form-page-info">
          <h3>Integración con One Drive</h3>{' '}
          <p>
            La integración con One Drive te permite sincronizar automáticamente
            tus documentos con AiKit de forma sencilla.
          </p>
          <h4>Proceso de integración</h4>
          <ol>
            <li>
              Selecciona la cuenta de One Drive que deseas conectar con AiKit.
            </li>
            <li>
              Una vez vinculada tu cuenta, encontrarás una nueva carpeta llamada
              "Apps/AiKit" en la raíz de tu Google Drive.
            </li>
            <li>
              Todos los documentos que coloques en la carpeta "Apps/AiKit" se
              sincronizarán automáticamente con nuestra plataforma.
            </li>
          </ol>
          <h5>Información importante</h5>
          <ul>
            <li>La sincronización se realiza automáticamente cada hora.</li>
            <li>
              Todos los documentos dentro de la carpeta AiKit serán procesados.
            </li>
            <li>
              Puedes organizar tus documentos en subcarpetas dentro de la
              carpeta AiKit.
            </li>
          </ul>
        </div>
      )}

      {provider === 'dropbox' && (
        <div className="knowledge-base-form-page-info">
          <h3>Integración con Dropbox</h3>{' '}
          <p>
            La integración con Dropbox te permite sincronizar automáticamente
            tus documentos con AiKit de forma sencilla.
          </p>
          <h4>Proceso de integración</h4>
          <ol>
            <li>
              Selecciona la cuenta de Dropbox que deseas conectar con AiKit.
            </li>
            <li>
              Una vez vinculada tu cuenta, encontrarás una nueva carpeta llamada
              "Apps/AiKit" en la raíz de tu Dropbox.
            </li>
            <li>
              Todos los documentos que coloques en la carpeta "Apps/AiKit" se
              sincronizarán automáticamente con nuestra plataforma.
            </li>
          </ol>
          <h5>Información importante</h5>
          <ul>
            <li>La sincronización se realiza automáticamente cada hora.</li>
            <li>
              Todos los documentos dentro de la carpeta AiKit serán procesados.
            </li>
            <li>
              Puedes organizar tus documentos en subcarpetas dentro de la
              carpeta AiKit.
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default observer(DriveIntegrationExplanations)
