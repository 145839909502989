import { useState, useEffect } from 'react'
import cx from 'classnames'
import Sidebar from './Sidebar'
import { useMediaQuery } from '@mui/material'
import EmptyState from './EmptyState'
import { useLocation } from 'react-router-dom'
import { sendPageView } from '@/features/analytics/google'
import TransitionOutlet from './TransitionOutlet'
import animation from '@/ui/lotties/Branding.json'
/* import Notifications from '@/components/Notifications' */

const MainLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const mobile = useMediaQuery('(max-width:900px)')
  const location = useLocation()

  useEffect(() => {
    sendPageView(location.pathname, 'App')
  }, [location])

  const handleCollapse = () => setIsCollapsed((prev) => !prev)

  return mobile ? (
    <div className="mobile-layout">
      <EmptyState
        animation={animation}
        title="Esta página no soporta dispositivos móviles"
        text="Por favor, reescala tu pantalla o utiliza un dispositivo con una pantalla más grande."
      />
    </div>
  ) : (
    <>
      {/* <Notifications /> */}
      <div className={cx('main-layout', { 'is-collapsed': isCollapsed })}>
        <Sidebar onClickCollapse={handleCollapse} />
        <div className="content">
          <div className="content-inner">
            <TransitionOutlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLayout
