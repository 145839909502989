import { FieldError } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

export class HTTPError extends Error {
  public statusCode: number
  public cmsErrors: CMSError[]

  constructor(statusCode: number, cmsErrors: CMSError[]) {
    const errorMessage =
      cmsErrors.length === 1
        ? cmsErrors[0].message
        : `Multiple errors: ${cmsErrors
            .map((error) => error.message)
            .join(', ')}`

    super(errorMessage)

    this.name = 'HTTPError'
    this.statusCode = statusCode
    this.cmsErrors = cmsErrors
  }
}

const CMSErrorSchema = z.object({
  // NOTE: Sometimes we don't have a code (Payload-native errors)
  code: z.string().optional(),
  message: z.string(),
  field: z.string().optional(),
})

export type CMSError = z.infer<typeof CMSErrorSchema>

export const parseHTTPError = async (rawResponse: Response) => {
  try {
    const result = await rawResponse.json()
    const parsedErrors = z.array(CMSErrorSchema).parse(result.errors)

    return new HTTPError(rawResponse.status, parsedErrors)
  } catch (error) {
    const responseText = await rawResponse.text().catch(() => null)

    console.error(`Error parsing HTTP error: ${error}\n${responseText}`)

    return new HTTPError(rawResponse.status, [
      {
        code: 'UNKNOWN',
        message: 'Error inesperado, intente más tarde.',
      },
    ])
  }
}

export const getFieldHTTPErrors = (error: HTTPError) => {
  const fieldErrors = error.cmsErrors.reduce(
    (acc, error) => {
      if (!error.field) return acc

      acc[error.field] = {
        type: 'manual',
        message: error.message,
      }
      return acc
    },
    {} as Record<string, FieldError>,
  )

  return fieldErrors
}

export const logAndRethrow = (msg: string) => (error: Error) => {
  console.error(msg, error)
  throw error
}

export const toastAndRethrow =
  (msg = 'Ha ocurrido un error inesperado.') =>
  <E extends Error>(error: E) => {
    toast.error(msg)
    throw error
  }
