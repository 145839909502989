import { useRef } from 'react'
import 'filepond/dist/filepond.min.css'

import { FilePond } from 'react-filepond'

interface Props {
  onFileUpload: (file: File) => Promise<void>
}

export default function FileUploader({ onFileUpload }: Props) {
  const pond = useRef<FilePond>(null)

  return (
    <div className="App">
      <FilePond
        ref={pond}
        server={{
          process: async (_fieldName, files, _metadata, load, error) => {
            try {
              await onFileUpload(files as File)
              load(files)
            } catch (err) {
              // NOTE: Error message is not used by FilePond, so we can pass any string
              error('Error al subir el documento')
            }
          },
        }}
        onprocessfiles={() => {
          setTimeout(() => {
            pond.current!.removeFiles()
          }, 1000)
        }}
        allowRevert={false}
        allowMultiple={true}
        maxFiles={25}
        labelIdle='Arrastra y suelta tus archivos aquí o <span class="filepond--label-action">busca en el dispositivo</span>'
        labelFileProcessing="Subiendo..."
        labelFileProcessingComplete="Fichero subido"
        labelTapToCancel="Pulsa para cancelar"
      />
    </div>
  )
}
