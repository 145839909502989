import cx from 'classnames'
import { Icon } from '@/ui/icon'

interface RoundButtonProps {
  icon: string
  label?: string
  variant?: 'default' | 'contrast'
  className?: string
  onClick?: () => void
}

const RoundButton = ({
  icon,
  label,
  variant = 'default',
  className,
  onClick,
}: RoundButtonProps) => {
  return (
    <button
      className={cx('round-button', { [variant]: variant }, className)}
      onClick={onClick}
    >
      {label && <span className="sr-only">{label}</span>}
      <Icon size={16} name={icon} />
    </button>
  )
}

export default RoundButton
