import { useEffect } from 'react'
import { Route, Navigate } from 'react-router-dom'
import rootStore from './store'
import UserNotActivePage from './pages/UserNotActivePage'
import UserOutOfCreditsPage from './pages/UserOutOfCreditsPage'
import NotFoundPage from '@/ui/error/NotFound'
import LinearProgress from '@/ui/LinearProgress'
import { User } from '@/features/auth/types'
import { KnowledgeBaseOAuthCallback } from './pages/KnowledgeBaseOAuthCallback'
import { KnowledgeBaseFormPage } from './pages/KnowledgeBaseFormPage'
import { KnowledgeBaseDetailPage } from './pages/KnowledgeBaseDetailPage'

export const useAuthRoutes = (user: User | null) => {
  const { organizations } = rootStore

  const userRole = user?.role
  const isUserActive = user?.active
  const chatTokensLeft = user?.chatTokensLeft ?? 0

  const isUserAdmin = userRole === 'admin'
  const isUserOrgManager = userRole === 'org-manager'

  useEffect(() => {
    if (!user) {
      return
    }

    if (isUserOrgManager) {
      organizations.fetchPlan()
    }
  }, [user])

  if (!user) {
    return null
  }

  const orgPlanNotLoaded =
    isUserOrgManager &&
    (organizations.initialLoadingPlan || !organizations.planInfo)

  if (orgPlanNotLoaded) {
    return (
      <Route
        path="*"
        element={
          <div className="top-loading-bar">
            <LinearProgress />
          </div>
        }
      />
    )
  }

  return (
    <>
      {isUserOrgManager ? (
        <>
          <Route
            path="/subscriptions"
            lazy={() => import('./pages/SubscriptionsPage')}
          />
          <Route path="/stats" lazy={() => import('./pages/OrgStatsPage')} />
          <Route path="/api" lazy={() => import('./pages/APIDocPage')} />
          <Route
            path="/company-info"
            lazy={() => import('./pages/CompanyInfoPage')}
          />
        </>
      ) : null}

      {isUserOrgManager || isUserAdmin ? (
        <>
          <Route
            path="/knowledge-bases/oauth-callback/:storageProvider"
            element={<KnowledgeBaseOAuthCallback />}
          />
          <Route
            path="/knowledge-bases/:id/edit"
            element={<KnowledgeBaseFormPage />}
          />
          <Route
            path="/knowledge-bases/:id"
            element={<KnowledgeBaseDetailPage />}
          />
          <Route
            path="/knowledge-bases"
            element={
              isUserAdmin ? (
                <Navigate replace to={`/knowledge-bases/global`} />
              ) : isUserOrgManager ? (
                <Navigate
                  replace
                  to={`/knowledge-bases/${user.organization}`}
                />
              ) : (
                <Navigate replace to="/knowledge-bases" />
              )
            }
          />
        </>
      ) : null}

      {isUserActive ? (
        chatTokensLeft > 0 ? (
          <>
            <Route path="/" element={<Navigate replace to="/home" />} />

            <Route
              path="/auth/google-callback"
              lazy={() => import('./pages/auth/GoogleOAuthCallbackPage')}
            />

            <Route
              path="/tools/:id/:threadId"
              lazy={() => import('./pages/ToolDetailPage')}
            />
            <Route
              path="/tools/:id"
              lazy={() => import('./pages/ToolDetailPage')}
            />
            {/* NOTE: DEPRECATED ROUTES
            <Route path="/tools" lazy={() => import('./pages/ToolListPage')} />
            <Route
              path="/toolsets/:slug"
              lazy={() => import('./pages/ToolListPage')}
            />
            <Route
              path="/resources"
              lazy={() => import('./pages/ResourcesPage')}
            /> */}
            <Route
              path="/assistants"
              lazy={() => import('./pages/GeneralAssistantPage')}
            />
            <Route path="/history" lazy={() => import('./pages/HistoryPage')} />
            <Route path="/home" lazy={() => import('./pages/HomePage')} />
            <Route path="/explore" lazy={() => import('./pages/ExplorePage')} />
            <Route
              path="/use-cases"
              lazy={() => import('./pages/UseCasesPage')}
            />
            <Route path="/agents" lazy={() => import('./pages/AgentsPage')} />

            <Route path="/support" lazy={() => import('./pages/SupportPage')} />

            <Route path="*" element={<NotFoundPage />} />
          </>
        ) : (
          <Route path="*" element={<UserOutOfCreditsPage />} />
        )
      ) : (
        <Route
          path="*"
          element={<UserNotActivePage isManager={isUserOrgManager} />}
        />
      )}
    </>
  )
}
