import React from 'react'
import { useLocation, useOutlet } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

const TransitionOutlet = (): React.JSX.Element => {
  const location = useLocation()
  const element = useOutlet()

  return (
    <AnimatePresence mode="wait" initial>
      {element && React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export default TransitionOutlet
