// markdownBuffer.ts

export enum ParseState {
  NORMAL = 'NORMAL',
  SQUARE_OPEN = 'SQUARE_OPEN', // reading text after `[`, until we see `]`
  AWAIT_PAREN = 'AWAIT_PAREN', // we saw `]`, waiting to see if next char is `(`
  LINK = 'LINK', // [ ... ]( ... )
  CODE_BLOCK = 'CODE_BLOCK', // ```...```
  INLINE_CODE = 'INLINE_CODE', // `...`
  STRONG = 'STRONG', // **...**
  EMPHASIS = 'EMPHASIS', // *...*
  HTML_LINK = 'HTML_LINK', // <a ...> ... </a>
}

/**
 * This class accumulates normal text, code blocks, bold, italic, placeholders,
 * and now also handles <a href="..."> links in HTML format.
 */
export class MarkdownBuffer {
  private state: ParseState = ParseState.NORMAL

  /** Accumulated normal text when in NORMAL state. */
  private normalTextBuffer = ''

  /** For the [ ... ] portion of a potential link or placeholder. */
  private squareBuffer = ''

  /** For the entire [ ... ]( ... ) link. */
  private linkBuffer = ''

  /** For triple backticks, single backticks, bold, italic. */
  private elementBuffer = ''

  /** For partial tokens at chunk boundaries. */
  private leftover = ''

  /**
   * We’ll store HTML link text here once we detect `<a ...>`,
   * until we see `</a>`.
   */
  private htmlLinkBuffer = ''

  processText(chunk: string): string[] {
    if (!chunk || typeof chunk !== 'string') return []

    // Combine leftover from previous chunk.
    let text = this.leftover + chunk
    this.leftover = ''

    if (typeof text !== 'string') return []

    const output: string[] = []
    let i = 0

    while (i < text.length) {
      const slice = text.slice(i)

      // =======================
      // NORMAL STATE
      // =======================
      if (this.state === ParseState.NORMAL) {
        // 1) DETECT CODE BLOCK
        if (slice.startsWith('```')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.CODE_BLOCK
          this.elementBuffer = '```'
          i += 3
        }
        // 2) DETECT INLINE CODE
        else if (slice.startsWith('`')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.INLINE_CODE
          this.elementBuffer = '`'
          i += 1
        }
        // 3) DETECT BOLD
        else if (slice.startsWith('**')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.STRONG
          this.elementBuffer = '**'
          i += 2
        }
        // 4) DETECT EMPHASIS
        else if (slice.startsWith('*')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.EMPHASIS
          this.elementBuffer = '*'
          i += 1
        }
        // 5) DETECT POTENTIAL MARKDOWN LINK `[`
        else if (slice.startsWith('[')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.SQUARE_OPEN
          this.squareBuffer = '['
          i += 1
        }
        // 6) DETECT HTML LINK `<a` or `<A` (case-insensitive check)
        else if (slice.toLowerCase().startsWith('<a')) {
          // We have some HTML link opening tag. Let's parse it.
          // Emit any normal text accumulated so far
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }

          // Start capturing everything in htmlLinkBuffer
          this.state = ParseState.HTML_LINK
          this.htmlLinkBuffer = '' // reset
          // We'll accumulate from here on, until we see `</a>`.
          // We'll handle partial `<a`, in leftover logic if chunk ends.
          // For now, consume just the `<a`, not the entire slice
          // because we want to accumulate it in the buffer char-by-char:
        } else {
          // Normal text
          this.normalTextBuffer += text[i]
          i += 1
        }
      }

      // =======================
      // HTML_LINK STATE
      // We keep reading all characters until we see `</a>` (case-insensitive).
      // =======================
      else if (this.state === ParseState.HTML_LINK) {
        this.htmlLinkBuffer += text[i]
        i += 1

        // Check case-insensitively if it ends with </a>
        if (this.htmlLinkBuffer.toLowerCase().endsWith('</a>')) {
          // We found the closing tag => done
          output.push(this.htmlLinkBuffer)
          this.htmlLinkBuffer = ''
          this.state = ParseState.NORMAL
        }
      }

      // =======================
      // SQUARE_OPEN STATE
      // reading inside `[ ... ]`
      // =======================
      else if (this.state === ParseState.SQUARE_OPEN) {
        const ch = text[i]
        i += 1
        this.squareBuffer += ch
        if (ch === ']') {
          // Now we check next char -> if it's `(` => link, else => placeholder
          this.state = ParseState.AWAIT_PAREN
        }
      }

      // =======================
      // AWAIT_PAREN STATE
      // we have `[some text]` just read,
      // now we see if next char is `(` => link, else => placeholder
      // =======================
      else if (this.state === ParseState.AWAIT_PAREN) {
        if (i >= text.length) {
          // No more chars in this chunk. We'll handle leftover logic after.
          break
        }

        const ch = text[i]
        if (ch === '(') {
          // It's a Markdown link
          this.linkBuffer = this.squareBuffer + '(' // e.g. "[some text]("
          this.squareBuffer = ''
          this.state = ParseState.LINK
          i += 1
        } else {
          // Not a link => emit the `[some text]` as normal text
          // then go back to NORMAL
          // (We haven't consumed `ch` => let NORMAL handle it)
          output.push(this.squareBuffer)
          this.squareBuffer = ''
          this.state = ParseState.NORMAL
          // do NOT do i++ here, so that on the next loop iteration
          // we re-check 'ch' in NORMAL state
        }
      }

      // =======================
      // LINK STATE
      // reading until we see `)`
      // =======================
      else if (this.state === ParseState.LINK) {
        const ch = text[i]
        i += 1
        this.linkBuffer += ch
        if (ch === ')') {
          // Link is complete
          output.push(this.linkBuffer)
          this.linkBuffer = ''
          this.state = ParseState.NORMAL
        }
      }

      // =======================
      // CODE_BLOCK STATE
      // =======================
      else if (this.state === ParseState.CODE_BLOCK) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('```')) {
          // done
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }

      // =======================
      // INLINE_CODE STATE
      // =======================
      else if (this.state === ParseState.INLINE_CODE) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('`')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }

      // =======================
      // STRONG STATE
      // =======================
      else if (this.state === ParseState.STRONG) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('**')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }

      // =======================
      // EMPHASIS STATE
      // =======================
      else if (this.state === ParseState.EMPHASIS) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('*')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
    }

    // ============== AFTER THE LOOP ==============
    if (i < text.length) {
      // We broke out early, e.g. AWAIT_PAREN or chunk ended in the middle of something
      this.leftover = text.slice(i)
    }

    // Handle leftover logic based on state
    if (this.state === ParseState.NORMAL) {
      let combined = this.normalTextBuffer
      this.normalTextBuffer = ''
      const leftoverLen = detectPartialTokenLength(combined)
      if (leftoverLen > 0) {
        const cutoff = combined.length - leftoverLen
        this.leftover += combined.slice(cutoff)
        combined = combined.slice(0, cutoff)
      }
      if (combined) {
        output.push(combined)
      }
    } else if (this.state === ParseState.SQUARE_OPEN) {
      // We have `[ ...` not finished. Keep it for next chunk
      this.leftover = this.squareBuffer + this.leftover
      this.squareBuffer = ''
    } else if (this.state === ParseState.AWAIT_PAREN) {
      // We read `[text]` but no next char to see if it's `(` or not
      this.leftover = this.squareBuffer + this.leftover
      this.squareBuffer = ''
    } else if (this.state === ParseState.LINK) {
      // partial link => store in leftover
      this.leftover = this.linkBuffer + this.leftover
      this.linkBuffer = ''
    } else if (this.state === ParseState.HTML_LINK) {
      // partial <a ...> => store in leftover
      this.leftover = this.htmlLinkBuffer + this.leftover
      this.htmlLinkBuffer = ''
    } else if (
      this.state === ParseState.CODE_BLOCK ||
      this.state === ParseState.INLINE_CODE ||
      this.state === ParseState.STRONG ||
      this.state === ParseState.EMPHASIS
    ) {
      // Keep in elementBuffer
    }

    return output.filter(Boolean)
  }

  /**
   * flushRemaining() is called when the entire stream is finished.
   * We can output any partially accumulated data so the user at least
   * sees it, even if it wasn’t “closed” properly.
   */
  flushRemaining(): string {
    let finalText = ''

    // normal text
    finalText += this.normalTextBuffer
    this.normalTextBuffer = ''

    // leftover bracket stuff
    finalText += this.squareBuffer
    this.squareBuffer = ''

    // leftover link stuff
    finalText += this.linkBuffer
    this.linkBuffer = ''

    // leftover HTML link stuff
    finalText += this.htmlLinkBuffer
    this.htmlLinkBuffer = ''

    // code/bold/italics partial
    finalText += this.elementBuffer
    this.elementBuffer = ''

    // leftover partial tokens
    finalText += this.leftover
    this.leftover = ''

    // reset state
    this.state = ParseState.NORMAL
    return finalText
  }
}

/**
 * A helper to see if the last 1..3 chars might be the start
 * of a Markdown token like ``` `, **, or `[`.
 */
function detectPartialTokenLength(text: string): number {
  if (!text) return 0
  const last3 = text.slice(-3)

  if (last3 === '```') return 3
  if (last3.endsWith('**')) return 2
  if (last3.endsWith('``')) return 2
  if (last3.endsWith('<a')) return 2

  const lastChar = last3.slice(-1)
  if (['`', '*', '[', '<'].includes(lastChar)) {
    return 1
  }
  return 0
}
