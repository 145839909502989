import animation from '@/ui/lotties/Analytics.json'
import EmptyState from '../EmptyState'
import TransitionLayout from '../TransitionLayout'

const NotFoundPage = () => {
  return (
    <TransitionLayout>
      <div className="mobile-layout">
        <EmptyState
          animation={animation}
          title="Parece que algo ha salido mal"
          text="Código de error: 404"
          hasButton
        />
      </div>
    </TransitionLayout>
  )
}

export default NotFoundPage
