import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'

import { MdDeleteOutline, MdFilePresent } from 'react-icons/md'
import { InternalDocument } from '@/features/knowledge-bases/api'
import LinearProgress from '@/ui/LinearProgress'
import { ChangeEvent } from 'react'

interface Props {
  searchQueryValue: string
  setSearchQuery: (value: string | undefined) => void

  documents: InternalDocument[]
  documentsBeingUploaded: string[]
  documentsBeingRemoved: string[]
  supportsFileUploads: boolean
  handleRemoveDocument: (pathOrId: string) => () => void
  handleDownloadDocument: (pathOrId: string, displayPath: string) => void
}

export default function DocumentTable({
  searchQueryValue,
  setSearchQuery,

  documents,
  documentsBeingRemoved,
  supportsFileUploads,
  handleRemoveDocument,
  handleDownloadDocument,
}: Props) {
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value || undefined)
  }

  // TODO: FilePond already gives us uploading progress feedback but we will reuse this logic
  // for displaying indexing progress. Leaving the whole thing here for the time being
  // const filteredDocumentsBeingUploaded = documentsBeingUploaded.filter((doc) =>
  //   doc.toLowerCase().includes(search.toLowerCase()),
  // )
  // const filteredDocumentsBeingUploaded: string[] = []

  return (
    <div>
      {/* TODO: maquetar properly */}
      <input
        style={{ maxWidth: 860, marginBottom: 16 }}
        onChange={onSearch}
        value={searchQueryValue}
        type="text"
        placeholder={'Búsqueda de documentos'}
      />

      <TableContainer component={Paper} sx={{ width: '100%', maxWidth: 860 }}>
        <Table aria-label="Tabla de documentos" data-cy="document-table">
          <TableHead>
            <TableRow>
              <TableCell aria-label="Celda de icono del documento" width={24} />
              <TableCell sx={{ fontWeight: 700 }}>Nombre de fichero</TableCell>
              <TableCell align="right" sx={{ fontWeight: 700 }}>
                Última modificación
              </TableCell>
              <TableCell aria-label="Celda de acciones" width={24} />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {filteredDocumentsBeingUploaded.map((name: string) => (
              <TableRow key={name}>
                <TableCell>
                  <CircularProgress size={24} />
                </TableCell>
                <TableCell
                  sx={{
                    '& a': {
                      borderBottom: '1px dotted',
                      transition: 'color 0.2s',
                      '&:hover': {
                        color: '#006dff',
                      },
                    },
                  }}
                >
                  {name}
                </TableCell>
                <TableCell align="right">
                  {new Date().toLocaleString()}
                </TableCell>

                <TableCell
                  sx={{
                    '& svg': {
                      width: 20,
                      height: 20,
                      opacity: 0.8,
                    },
                  }}
                ></TableCell>
              </TableRow>
            ))} */}

            {documents.map((doc: InternalDocument) => (
              <TableRow key={doc.path_or_id} data-cy="kb-document">
                <TableCell
                  sx={{
                    '& svg': {
                      width: 20,
                      height: 20,
                      opacity: 0.87,
                      position: 'relative',
                      top: 2,
                    },
                  }}
                >
                  <MdFilePresent />
                </TableCell>
                <TableCell
                  sx={{
                    '& a': {
                      borderBottom: '1px dotted',
                      transition: 'color 0.2s',
                      '&:hover': {
                        color: '#006dff',
                      },
                    },
                  }}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadDocument(doc.path_or_id, doc.display_path)
                    }
                  >
                    {doc.display_path}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {doc.updated_at
                    ? new Date(doc.updated_at).toLocaleString()
                    : '---'}
                </TableCell>

                {supportsFileUploads && (
                  <TableCell
                    sx={{
                      '& svg': {
                        width: 20,
                        height: 20,
                        opacity: 0.8,
                      },
                    }}
                  >
                    {documentsBeingRemoved.includes(doc.path_or_id) ? (
                      <LinearProgress />
                    ) : (
                      <IconButton
                        data-cy="delete-document-button"
                        aria-label="Eliminar documento"
                        onClick={handleRemoveDocument(doc.path_or_id)}
                      >
                        <MdDeleteOutline />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
