import rootStore from '@/app/store'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { TbActivity, TbBooks } from 'react-icons/tb'
import { truncate } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import { Icon, Icons } from '@/ui/icon'

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  const { auth, tools, organizations, knowledgeBases, chat, tool } = rootStore

  const handleLogout = () => {
    auth.logout().then(() => {
      tools.resetState()
      chat.resetState()
      tool.resetState()
      organizations.resetState()
      knowledgeBases.resetState()

      navigate('/home')

      handleClose()
    })
  }

  const userRole = auth.user.role

  const navAndClose = (path: string) => {
    navigate(path)
    handleClose()
  }

  return (
    <div className="userMenu">
      <button
        className="userMenu-button"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div aria-hidden="true" className="userMenu-avatar">
          <Icon size={28} name={Icons.User} />
        </div>
        <span className="userMenu-name">
          {truncate(auth.user.email.split('@')[0], 16).toLocaleLowerCase()}
        </span>
        <Icon className="userMenu-icon" size={14} name={Icons.ChevronDown} />
      </button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            minWidth: 170,
            translate: '0 -10px',
            boxShadow:
              '0px 2px 16px 0px #00000029, 0px 2px 8px 0px #00000014, 0px 2px 4px 0px #0000000A',
          },
          '.MuiMenuItem-root': {
            columnGap: 1,
            paddingBlock: 1,
            color: 'var(--neutral-600)',
            transition: 'all 200ms',

            '&:hover': {
              color: 'var(--neutral-black)',
              backgroundColor: 'var(--platform-grey-200)',
            },
          },
          '.MuiListItemIcon-root[class]': {
            minWidth: 14,
            color: 'currentcolor',
          },
          '.MuiTypography-root': {
            font: 'var(--link)',
            color: 'currentColor',
          },
        }}
      >
        {/* {auth.user.active ? (
                <div className="menu-header">
                  Creditos: {auth.user.chatTokensLeft}
                </div>
            ) : null} */}

        {userRole === 'org-manager'
          ? [
              <MenuItem
                key="subscriptions"
                onClick={() => navAndClose('/subscriptions')}
              >
                <ListItemIcon>
                  <Icon size={14} name={Icons.Group} />
                </ListItemIcon>
                <ListItemText>Organización</ListItemText>
              </MenuItem>,
              <MenuItem key="org-stats" onClick={() => navAndClose('/stats')}>
                <ListItemIcon>
                  <TbActivity />
                </ListItemIcon>
                <ListItemText>Estadísticas</ListItemText>
              </MenuItem>,
              // <MenuItem key="api" onClick={() => navAndClose('/api')}>
              //   <ListItemIcon>
              //     <Icon size={14} name={Icons.Code} />
              //   </ListItemIcon>
              //   <ListItemText>API</ListItemText>
              // </MenuItem>,
            ]
          : null}

        {userRole === 'admin' ? (
          <MenuItem
            key="global-knowledge-base"
            onClick={() => navAndClose('/knowledge-bases/global')}
          >
            <ListItemIcon>
              <TbBooks />
            </ListItemIcon>
            <ListItemText>Documentos Toolkit</ListItemText>
          </MenuItem>
        ) : null}

        <MenuItem key="history" onClick={() => navAndClose('/history')}>
          <ListItemIcon>
            <Icon size={14} name={Icons.History} />
          </ListItemIcon>
          <ListItemText sx={{}}>Historial</ListItemText>
        </MenuItem>
        <MenuItem key="support" onClick={() => navAndClose('/support')}>
          <ListItemIcon>
            <Icon size={14} name={Icons.Help} />
          </ListItemIcon>
          <ListItemText sx={{}}>Soporte</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Icon size={14} name={Icons.Logout} />
          </ListItemIcon>
          <ListItemText>Cerrar sesión</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu
