import getIcon from './getIcon'

type IconProps = {
  name: string
  size?: number
  className?: string
}

const Icon = ({ name, size = 24, className, ...rest }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      className={className}
      {...rest}
    >
      {getIcon(name)}
    </svg>
  )
}

export default Icon
