import rootStore from '@/app/store'
import { makeRepeatingPromise } from '@/utils/async'
import { useEffect, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { KB_DETAIL_POLLING_FREQUENCY } from './domain'
import { PaginatedInternalDocuments } from './api'

export const useKnowledgeBaseById = (
  knowledgeBaseId: string | undefined,
  enablePolling = false,
) => {
  const { knowledgeBases } = rootStore

  useEffect(() => {
    if (!knowledgeBaseId) return

    if (enablePolling) {
      const { run, cancel } = makeRepeatingPromise(() => {
        return knowledgeBases.fetchById(knowledgeBaseId)
      }, KB_DETAIL_POLLING_FREQUENCY)

      run()

      return cancel
    }

    const knowledgeBaseCached =
      knowledgeBases.knowledgeBaseCache[knowledgeBaseId]

    if (!knowledgeBaseCached) {
      knowledgeBases.fetchById(knowledgeBaseId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeBaseId])

  return {
    isLoading: knowledgeBaseId
      ? knowledgeBases.loadingStates[knowledgeBaseId]
      : false,
    knowledgeBase: knowledgeBaseId
      ? knowledgeBases.knowledgeBaseCache[knowledgeBaseId]
      : null,
  }
}

const DEFAULT_PAGE_SIZE = 50

export const useKnowledgeBaseDocuments = (
  knowledgeBaseId: string | undefined,
  pageSize = DEFAULT_PAGE_SIZE,
) => {
  const { knowledgeBases } = rootStore

  const [searchQueryValue, setSearchQuery] = useState<string | undefined>(
    undefined,
  )
  const debouncedSearchQuery = useDebounce(searchQueryValue, 300)

  const [paginatedDocuments, setPaginatedDocuments] =
    useState<PaginatedInternalDocuments | null>(null)

  const fetchAndStoreDocuments = (
    knowledgeBaseId: string,
    limit: number,
    offset?: number,
  ) => {
    knowledgeBases
      .fetchDocuments(knowledgeBaseId, limit, offset, searchQueryValue)
      .then((paginatedResponse) => setPaginatedDocuments(paginatedResponse))
  }

  useEffect(() => {
    if (!knowledgeBaseId) return

    if (!debouncedSearchQuery) {
      fetchAndStoreDocuments(knowledgeBaseId, pageSize)
    } else {
      // NOTE: Reset the page when the search query changes
      fetchAndStoreDocuments(knowledgeBaseId, pageSize, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery])

  const fetchPreviousPage = () => {
    if (
      !knowledgeBaseId ||
      !paginatedDocuments ||
      !paginatedDocuments.previous_page_params
    ) {
      return
    }

    const { limit, offset } = paginatedDocuments.previous_page_params

    fetchAndStoreDocuments(knowledgeBaseId, limit, offset)
  }

  const refreshPage = (reset = false) => {
    if (!knowledgeBaseId) return

    if (reset) {
      fetchAndStoreDocuments(knowledgeBaseId, pageSize)
    } else {
      fetchAndStoreDocuments(
        knowledgeBaseId,
        pageSize,
        paginatedDocuments?.offset,
      )
    }
  }

  const fetchNextPage = () => {
    if (
      !knowledgeBaseId ||
      !paginatedDocuments ||
      !paginatedDocuments.next_page_params
    ) {
      return
    }

    const { limit, offset } = paginatedDocuments.next_page_params

    fetchAndStoreDocuments(knowledgeBaseId, limit, offset)
  }

  return {
    isLoadingInitialDocs: !paginatedDocuments,
    count: paginatedDocuments?.total,
    documents: paginatedDocuments?.documents ?? [],

    displayedDocuments: {
      from: paginatedDocuments?.offset ?? 0,
      to:
        (paginatedDocuments?.offset ?? 0) +
        (paginatedDocuments?.limit ?? pageSize),
    },

    searchQueryValue: searchQueryValue ?? '',
    setSearchQuery,

    hasPreviousPage: !!paginatedDocuments?.previous_page_params,
    hasNextPage: !!paginatedDocuments?.next_page_params,

    fetchPreviousPage,
    refreshPage,
    fetchNextPage,
  }
}
