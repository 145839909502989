import { makeAutoObservable, runInAction } from 'mobx'

import { RootStore } from '@/app/store'
import {
  KnowledgeBaseAPI,
  KnowledgeBaseDTO,
  KnowledgeBaseModel,
  OAuthFlowStartResult,
  RagatronError,
  UpdateKnowledgeBaseModel,
} from './api'
import { toast } from 'react-toastify'
import { toastAndRethrow } from '@/utils/error'

const LOCAL_STORAGE_CSRF_TOKEN_KEY = 'oauthCSRFToken'

export class KnowledgeBaseStore {
  rootStore: RootStore
  knowledgeBaseAPI: KnowledgeBaseAPI

  loadingStates: Record<string, boolean> = {}

  documentsBeingUploaded: Record<string, boolean> = {}
  documentsBeingRemoved: Record<string, boolean> = {}

  knowledgeBaseCache: Record<string, KnowledgeBaseModel> = {}

  oauthCSRFToken: string | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)

    this.knowledgeBaseAPI = new KnowledgeBaseAPI()
  }

  // NOTE: Called on logout
  public resetState() {
    this.loadingStates = {}
    this.documentsBeingUploaded = {}
    this.documentsBeingRemoved = {}
    this.knowledgeBaseCache = {}
  }

  async fetchById(id: string) {
    this.loadingStates[id] = true

    try {
      const knowledgeBase = await this.knowledgeBaseAPI
        .fetchById(id)
        .catch(toastAndRethrow())

      runInAction(() => {
        this.knowledgeBaseCache[id] = knowledgeBase
      })

      return knowledgeBase
    } finally {
      runInAction(() => {
        this.loadingStates[id] = false
      })
    }
  }

  async fetchDocuments(
    id: string,
    limit: number = 100,
    offset: number = 0,
    searchQuery?: string,
  ) {
    const paginatedDocuments = await this.knowledgeBaseAPI
      .fetchDocuments(id, limit, offset, searchQuery)
      .catch(toastAndRethrow('Error obteniendo documentos'))

    return paginatedDocuments
  }

  async update(id: string, knowledgeBase: UpdateKnowledgeBaseModel) {
    await this.knowledgeBaseAPI
      .update(id, knowledgeBase)
      .catch(toastAndRethrow('Error actualizando base de conocimiento'))

    await this.fetchById(id)
  }

  async uploadFile(id: string, file: File) {
    this.documentsBeingUploaded[file.name] = true

    try {
      await this.knowledgeBaseAPI.uploadFile(id, file)
    } catch (error) {
      if (
        error instanceof RagatronError &&
        error.detail === 'KB_LIMIT_EXCEEDED'
      ) {
        toast.error(
          'Has alcanzado el límite de documentos para esta base de conocimiento. Por favor, elimina algunos documentos.',
        )
      } else {
        toast.error('Error al subir el documento')
      }

      throw error
    } finally {
      runInAction(() => {
        delete this.documentsBeingUploaded[file.name]
      })
    }
  }

  async downloadDocument(id: string, documentId: string, displayPath: string) {
    await this.knowledgeBaseAPI.downloadDocument(id, documentId, displayPath)
  }

  async removeDocument(id: string, documentId: string) {
    this.documentsBeingRemoved[documentId] = true

    await this.knowledgeBaseAPI
      .deleteDocument(id, documentId)
      .catch(toastAndRethrow('Error eliminando documento'))

    runInAction(() => {
      delete this.documentsBeingRemoved[documentId]
    })
  }

  async startOAuthFlow(provider: string, kbId: string, redirectURI: string) {
    const { redirect_url, csrf_token }: OAuthFlowStartResult =
      await this.knowledgeBaseAPI
        .connectOAuth2(provider, kbId, redirectURI)
        .catch(toastAndRethrow('Error autenticando con proveedor'))

    localStorage.setItem(LOCAL_STORAGE_CSRF_TOKEN_KEY, csrf_token)

    window.location.href = redirect_url
  }

  async completeOAuthStorageProviderChange(
    provider: string,
    redirectURI: string,
    query: Record<string, string>,
  ) {
    const csrfToken = localStorage.getItem(LOCAL_STORAGE_CSRF_TOKEN_KEY) ?? ''

    localStorage.removeItem(LOCAL_STORAGE_CSRF_TOKEN_KEY)

    const authorizedKb: KnowledgeBaseDTO = await this.knowledgeBaseAPI
      .verifyOAuth2(provider, redirectURI, csrfToken, query)
      .catch(toastAndRethrow('Error autenticando con proveedor'))

    return authorizedKb
  }
}
