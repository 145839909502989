import illustartion from '/images/illustrations/maintainence.svg'
import EmptyState from '@/ui/EmptyState'

export const GlobalErrorPage = () => {
  const hardRefresh = () => {
    window.location.reload()
  }

  return (
    <div className="globalErrorPage">
      <div className="mobile-layout">
        <EmptyState
          hasButton
          image={illustartion}
          title="¡Vaya! Algo salió mal"
          text="Por favor, prueba a recargar la página."
          buttonText="Recargar"
          onButtonClick={hardRefresh}
        />
      </div>
    </div>
  )
}
