export const LOCAL_STORAGE_PROVIDER = 'azure_blob_storage'

export const KB_DETAIL_POLLING_FREQUENCY = 1000 * 60 * 1 // 1 minute

export const TOTAL_KB_STORAGE = 1 // GBs

export const checkStorageProviderOAuthSupport = (provider: string) => {
  return provider !== LOCAL_STORAGE_PROVIDER
}

export const bytesToGB = (bytes: number) => {
  return +(bytes / (1024 * 1024 * 1024)).toFixed(2)
}
