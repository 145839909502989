import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { Icon } from '@/ui/icon'

interface LinkProps {
  children: React.ReactNode
  to: string
  isActive?: boolean
  isDisabled?: boolean
  iconName?: string
  className?: string
}

const Link = ({
  children,
  to,
  iconName,
  isActive,
  isDisabled,
  className,
  ...props
}: LinkProps) => {
  return (
    <RouterLink
      className={cx('link', { isActive, isDisabled }, className)}
      to={to}
      {...props}
    >
      {iconName && <Icon size={14} name={iconName} className="link-icon" />}
      <span className="link-text">{children}</span>
    </RouterLink>
  )
}

export default observer(Link)
