import { observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'

import rootStore from '../store'

import Box from '@mui/material/Box'

import { MdRefresh } from 'react-icons/md'
import DocumentTable from '@/components/DocumentTable'
import FileUploader from '@/components/FileUploader'
import {
  useKnowledgeBaseById,
  useKnowledgeBaseDocuments,
} from '@/features/knowledge-bases/hooks'
import { Chip } from '@mui/material'
import LinearProgress from '@/ui/LinearProgress'
import { PlanInfo } from '@/features/organizations/types'
import { User } from '@/features/auth/types'
import { MASKED_GLOBAL_TOOLKIT_ID } from '@/features/knowledge-bases/api'
import StorageConsumption from '@/components/StorageConsumption'
import {
  bytesToGB,
  LOCAL_STORAGE_PROVIDER,
  TOTAL_KB_STORAGE,
} from '@/features/knowledge-bases/domain'
import TransitionLayout from '@/ui/TransitionLayout'
import CantAccessKnowledgeBase from '@/components/CantAccessKnowledgeBase'
import CTA from '@/ui/CTA'

const checkRAGAccess = (
  knowledgeBaseId: string | undefined,
  planInfo: PlanInfo | undefined,
  user: User,
) => {
  if (!knowledgeBaseId) return false

  if (knowledgeBaseId === MASKED_GLOBAL_TOOLKIT_ID && user.role === 'admin') {
    return true
  }

  if (!planInfo) return false

  return Boolean(planInfo?.hasAccessToRAG && planInfo?.hasRAGEnabled)
}

export const KnowledgeBaseDetailPage = observer(() => {
  const params = useParams()
  const knowledgeBaseId = params.id

  const { organizations, auth, knowledgeBases } = rootStore

  const canAccessRAG = checkRAGAccess(
    knowledgeBaseId,
    organizations.planInfo,
    auth.user,
  )

  if (!canAccessRAG) {
    return <CantAccessKnowledgeBase />
  }

  const { knowledgeBase } = useKnowledgeBaseById(
    canAccessRAG ? knowledgeBaseId : undefined,
    canAccessRAG,
  )

  const {
    isLoadingInitialDocs,
    count,
    documents,

    displayedDocuments,

    searchQueryValue,
    setSearchQuery,

    hasPreviousPage,
    hasNextPage,

    fetchPreviousPage,
    refreshPage,
    fetchNextPage,
  } = useKnowledgeBaseDocuments(canAccessRAG ? knowledgeBaseId : undefined, 20)

  if (
    knowledgeBaseId &&
    (!knowledgeBases.knowledgeBaseCache[knowledgeBaseId] ||
      isLoadingInitialDocs)
  ) {
    return (
      <div className="top-loading-bar">
        <LinearProgress />
      </div>
    )
  }

  if (!knowledgeBaseId || !knowledgeBase) {
    return <div>Knowledge Base not found</div>
  }

  const docsBeingUploaded = Object.keys(knowledgeBases.documentsBeingUploaded)
  const docsBeingRemoved = Object.keys(knowledgeBases.documentsBeingRemoved)

  const handleFileUpload = async (file: File): Promise<void> => {
    await knowledgeBases.uploadFile(knowledgeBaseId, file)
    await refreshPage(true)
  }

  const handleRemoveDocument = (documentId: string) => async () => {
    await knowledgeBases.removeDocument(knowledgeBaseId, documentId)
    await refreshPage()
  }

  const handleDownloadDocument = (documentId: string, displayPath: string) => {
    return knowledgeBases.downloadDocument(
      knowledgeBaseId,
      documentId,
      displayPath,
    )
  }

  return (
    <TransitionLayout>
      <div className="centered-page-content">
        <div className="knowledge-base-page" data-cy="knowledge-base-page">
          <h1 className="temporal-headingTitle">{knowledgeBase.name}</h1>
          <p className="temporal-headingCaption">
            Base de conocimiento de tu empresa
          </p>
          <div className="temporal-row is-centered">
            <p>
              Última actualización:{' '}
              {new Date(knowledgeBase.updated_at).toLocaleString()}
            </p>
            <div>
              {knowledgeBase.storage_provider_config.provider !==
              LOCAL_STORAGE_PROVIDER ? (
                knowledgeBase.sync_status === 'synced' ? (
                  <Chip
                    label={'Sincronizado'}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(0, 109, 255, 0.1)',
                      color: '#006dff',
                    }}
                  />
                ) : knowledgeBase.sync_status === 'syncing' ? (
                  <Chip
                    label={'Sincronizando con proveedor'}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(255, 193, 7, 0.2)',
                      color: '#f57c00',
                    }}
                  />
                ) : knowledgeBase.sync_status === 'error' ? (
                  <Chip
                    label={'Error de Sincronización'}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(255, 0, 0, 0.1)',
                      color: '#ff0000',
                    }}
                  />
                ) : null
              ) : null}

              {knowledgeBase.indexer_status === 'idle' ? (
                <Chip
                  label={'Contenido Indexado'}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(0, 109, 255, 0.1)',
                    color: '#006dff',
                  }}
                />
              ) : knowledgeBase.indexer_status === 'active' ? (
                <Chip
                  label={'Indexando'}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 193, 7, 0.2)',
                    color: '#f57c00',
                  }}
                />
              ) : knowledgeBase.indexer_status === 'error' ? (
                <Chip
                  label={'Error'}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    color: '#ff0000',
                  }}
                />
              ) : null}
            </div>
          </div>

          <CTA
            size="medium"
            variant="system"
            type="button"
            to={`/knowledge-bases/${knowledgeBaseId}/edit`}
          >
            Cambiar método de sincronización
          </CTA>

          <div className="temporal-disclaimer">
            <p>
              La información de los documentos indexados será accesible y podrá
              ser consultada por los miembros de tu organización a través de la
              herramienta{' '}
              <Link to="/tools/experto-en-tu-empresa">
                experto en tu empresa
              </Link>
              .
            </p>
          </div>

          <div className="temporal-row is-centered">
            <h2 className="temporal-headingSubitle">Documentos indexados</h2>

            <button onClick={() => refreshPage()}>
              <MdRefresh size={16} />
            </button>
          </div>

          <Box sx={{ maxWidth: 860 }}>
            <DocumentTable
              searchQueryValue={searchQueryValue}
              setSearchQuery={setSearchQuery}
              documents={documents}
              supportsFileUploads={!!knowledgeBase.support_file_upload}
              documentsBeingUploaded={docsBeingUploaded}
              documentsBeingRemoved={docsBeingRemoved}
              handleRemoveDocument={handleRemoveDocument}
              handleDownloadDocument={handleDownloadDocument}
            />

            <br />
            <div>
              <span>
                Mostrando documentos {displayedDocuments.from + 1} -{' '}
                {displayedDocuments.to} de {count}
              </span>
              <button
                className="pagination-button"
                disabled={!hasPreviousPage}
                onClick={fetchPreviousPage}
              >
                {'<<'}
              </button>
              <button
                className="pagination-button"
                disabled={!hasNextPage}
                onClick={fetchNextPage}
              >
                {'>>'}
              </button>
            </div>

            {knowledgeBase.support_file_upload ? (
              <Box sx={{ mt: 2 }} data-cy="file-uploader">
                <FileUploader onFileUpload={handleFileUpload} />
              </Box>
            ) : null}
          </Box>

          <br />

          <StorageConsumption
            consumedGB={bytesToGB(knowledgeBase.total_documents_size)}
            totalGB={TOTAL_KB_STORAGE}
          />
        </div>
      </div>
    </TransitionLayout>
  )
})
