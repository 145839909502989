import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Icon } from '@/ui/icon'
import cx from 'classnames'

type CTAVariant = 'default' | 'system' | 'gradient'

interface CTAProps {
  variant?: CTAVariant
  size?: 'small' | 'medium' | 'large'
  isAnimated?: boolean
  isDisabled?: boolean
  onClick?: () => void
  to?: string
  iconLeftName?: string
  iconRightName?: string
  type?: 'button' | 'submit' | 'reset'
  form?: string
  children: React.ReactNode
}

const CTA = ({
  variant = 'default',
  size = 'small',
  isAnimated = false,
  isDisabled = false,
  onClick,
  type = 'button',
  form,
  to,
  iconLeftName,
  iconRightName,
  children,
}: CTAProps) => {
  const content = (
    <>
      {iconLeftName && (
        <Icon size={14} name={iconLeftName} className="cta-iconLeft" />
      )}
      <span className="cta-text">{children}</span>
      {iconRightName && (
        <Icon size={14} name={iconRightName} className="cta-iconRight" />
      )}
    </>
  )

  if (to) {
    return (
      <Link className={cx('cta', variant, size, { isAnimated })} to={to}>
        {content}
      </Link>
    )
  }

  return (
    <button
      className={cx('cta', variant, size, { isAnimated, isDisabled })}
      onClick={onClick}
      type={type}
      form={form}
    >
      {content}
    </button>
  )
}

export default observer(CTA)
