export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const makeRepeatingPromise = <T>(
  promiseCreator: () => Promise<T>,
  callEveryMs: number,
  abortController = new AbortController(),
) => {
  const cancel = (error?: Error) => {
    abortController.abort(error)
  }

  const runLoop = async () => {
    while (!abortController.signal.aborted) {
      try {
        await promiseCreator()
        await delay(callEveryMs)
      } catch (error) {
        cancel(error as Error)
      }
    }
  }

  return {
    cancel,
    run: runLoop,
  }
}
