import { authFetch, fetchJSON } from '@/utils/http'
import { API_ROUTE } from '../backend/config'
import {
  CompanyPublicInfo,
  ListMembersResponse,
  OrgDailyStats,
  PendingMemberInvite,
  PlanInfo,
} from './types'

interface FetchPlanResponse {
  plan: PlanInfo
  members: ListMembersResponse
  pendingInvites: PendingMemberInvite[]
}

export const fetchPlan = async (): Promise<FetchPlanResponse> => {
  return await fetchJSON(`${API_ROUTE}/organizations/plan`)
}

export const fetchStats = async (
  interval: number,
): Promise<OrgDailyStats[]> => {
  return await fetchJSON(
    `${API_ROUTE}/organizations/stats?interval=${interval}`,
  )
}

export const inviteMember = async (email: string) => {
  return await fetchJSON(`${API_ROUTE}/organizations/members`, {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}

type AcceptInviteParams = {
  inviteToken: string
  password: string
  name: string
  surname: string
  jobTitle: string
  department: string
}

export const acceptInvite = async (params: AcceptInviteParams) => {
  return await fetchJSON(`${API_ROUTE}/organizations/accept-invite`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export type UpdateMemberParams = {
  active?: boolean
  role?: 'org-manager' | 'reader'
}

export const updateMember = async (
  memberId: string,
  params: UpdateMemberParams,
) => {
  return await fetchJSON(`${API_ROUTE}/organizations/members/${memberId}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  })
}

export const updatePlan = async (values: {
  hasRAGEnabled: boolean
}): Promise<Pick<FetchPlanResponse, 'plan'>> => {
  return await fetchJSON(`${API_ROUTE}/organizations/plan`, {
    method: 'PATCH',
    body: JSON.stringify(values),
  })
}

export const autocompleteCompanyInfoFromFiles = async (
  files: File[],
): Promise<CompanyPublicInfo> => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })

  const response = await authFetch(
    `${API_ROUTE}/organizations/autocomplete-info`,
    {
      method: 'POST',
      body: formData,
    },
  )

  return await response.json()
}

export const fetchPublicCompanyInfo = async (): Promise<CompanyPublicInfo> => {
  return await fetchJSON(`${API_ROUTE}/organizations/info`)
}

export const updateCompanyInfo = async (
  data: CompanyPublicInfo,
): Promise<void> => {
  return await fetchJSON(`${API_ROUTE}/organizations/info`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}
