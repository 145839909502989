import MuiLinearProgress from '@mui/material/LinearProgress'
import { linearProgressClasses } from '@mui/material/LinearProgress'

const LinearProgress = () => {
  return (
    <MuiLinearProgress
      sx={{
        position: 'absolute',
        zIndex: 1,
        insetInline: 0,
        insetBlock: 0,
        [`& .${linearProgressClasses.bar}`]: {
          background: 'var(--gradient-primary)',
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: 'var(--neutral-200)',
        },
        height: '2px',
      }}
    />
  )
}

export default LinearProgress
