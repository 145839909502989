import { useEffect } from 'react'
import rootStore from '../store'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import LinearProgress from '@/ui/LinearProgress'

export const KnowledgeBaseOAuthCallback = () => {
  const params = useParams()
  const navigate = useNavigate()

  const { knowledgeBases } = rootStore

  const goBackHome = () => {
    navigate('/auth/login', {
      replace: true,
    })
  }

  useEffect(() => {
    const storageProvider = params.storageProvider

    if (typeof storageProvider !== 'string') {
      goBackHome()
      return
    }

    const queryParams = new URLSearchParams(window.location.search)

    const main = async () => {
      // NOTE: This exact URL but without any search params.
      const redirectURL = `${window.location.origin}${window.location.pathname}`

      await knowledgeBases.completeOAuthStorageProviderChange(
        storageProvider,
        redirectURL,
        Object.fromEntries(queryParams),
      )

      toast.success('Proveedor conectado correctamente.')

      navigate(`/knowledge-bases`, { replace: true })
    }

    main().catch(() => {
      goBackHome()
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="top-loading-bar">
      <LinearProgress />
    </div>
  )
}
