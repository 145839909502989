// KnowledgeBaseForm.tsx
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { MdArrowBackIosNew } from 'react-icons/md'

import toolsLogo from '../../assets/images/tools-logo.svg'
import googleDriveLogo from '../../assets/images/google-drive-logo.svg'
import oneDriveLogo from '../../assets/images/one-drive-logo.svg'
import dropboxLogo from '../../assets/images/dropbox-logo.svg'
import { useKnowledgeBaseById } from '@/features/knowledge-bases/hooks'
import LinearProgress from '@/ui/LinearProgress'
import {
  checkStorageProviderOAuthSupport,
  LOCAL_STORAGE_PROVIDER,
} from '@/features/knowledge-bases/domain'
import { toast } from 'react-toastify'
import { ENABLE_DRIVE_INTEGRATIONS } from '@/config/feature-flags'
import rootStore from '../store'
import {
  KnowledgeBaseModel,
  StorageProviderConfig,
} from '@/features/knowledge-bases/api'
import TransitionLayout from '@/ui/TransitionLayout'
import CTA from '@/ui/CTA'
import DriveIntegrationExplanations from '@/components/DriveIntegrationExplanations'

type KnowledgeBaseFormValues = {
  name: string
  storage_provider_config: {
    authorized: boolean
    provider: string
  }
}

type KnowledgeBaseFormProps = {
  mode: 'create' | 'edit'
  onSubmit: (data: KnowledgeBaseFormValues) => void
  defaultValues?: Partial<KnowledgeBaseFormValues>
  syncStatus?: KnowledgeBaseModel['sync_status']
  isSubmitting: boolean
}

type RadioOptionWrapperProps = {
  label: string
  imageSrc: string
  children: React.ReactNode
  selected: boolean
}

const RadioOptionWrapper = ({
  label,
  imageSrc,
  children,
  selected,
}: RadioOptionWrapperProps) => {
  return (
    <ButtonBase>
      <Card
        sx={{
          width: '100%',
        }}
      >
        <InputLabel
          className={selected ? 'is-selected' : ''}
          sx={{
            p: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: 16,
            color: '#000',
            cursor: 'pointer',
            '&.is-selected': {
              bgcolor: 'rgba(0, 109, 255, 0.075)',
              '&:hover': { bgcolor: 'rgba(0, 109, 255, 0.075)' },
            },
            '&:hover': {
              bgcolor: '#f9f9f9',
            },
            '& > input': {
              width: 16,
              height: 16,
              cursor: 'pointer',
            },
            '& img': {
              width: 24,
              height: 24,
              cursor: 'pointer',
            },
          }}
        >
          <Box
            component="span"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <img src={imageSrc} alt={`${label} Logo`} />
            {label}
          </Box>
          {children}
        </InputLabel>
      </Card>
    </ButtonBase>
  )
}

const KnowledgeBaseForm: React.FC<KnowledgeBaseFormProps> = ({
  mode,
  defaultValues,
  onSubmit,
  syncStatus,
  isSubmitting,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<KnowledgeBaseFormValues>({
    defaultValues: defaultValues ?? {},
  })
  const navigate = useNavigate()

  const values = watch()
  const provider = values.storage_provider_config.provider
  const isKBSyncing = syncStatus === 'syncing'

  return (
    <TransitionLayout>
      <div className="centered-page-content">
        <div className="knowledge-base-form-page">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              component="div"
              size="small"
              sx={{
                color: '#4569FC',
                textTransform: 'none',
                mb: 1,
                marginBottom: '32px',
                '& svg': { width: 12, height: 12 },
              }}
              role="presentation"
              startIcon={<MdArrowBackIosNew />}
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>

            <Stack
              direction="column"
              gap={4}
              sx={{ width: '100%', maxWidth: 600 }}
            >
              <h1 className="temporal-headingTitle">
                {mode === 'edit'
                  ? 'Opciones de base de conocimiento'
                  : 'Nueva base de conocimiento.'}
              </h1>

              {isKBSyncing && (
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  sx={{ color: '#DAA520' }}
                >
                  No se puede editar la base de conocimiento mientras se está
                  sincronizando.
                </Typography>
              )}

              <Box>
                <InputLabel
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: errors.name ? '#D32F3F' : '#000',
                    mb: 1,
                  }}
                  htmlFor="name"
                >
                  Nombre
                </InputLabel>
                <TextField
                  sx={{ bgcolor: '#fff', '& input': { fontSize: 16 } }}
                  disabled
                  id="name"
                  {...register('name', {
                    required: 'Introduzca un nombre para continuar',
                  })}
                  fullWidth
                  error={!!errors.name}
                />
                {errors.name && (
                  <FormHelperText error>{errors.name.message}</FormHelperText>
                )}
              </Box>

              <Box>
                <InputLabel
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: errors.name ? '#D32F3F' : '#000',
                    mb: 1,
                  }}
                  htmlFor="name"
                >
                  Método de sincronización
                </InputLabel>

                <Box
                  sx={{
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    mt: 1,
                  }}
                >
                  <Controller
                    name="storage_provider_config.provider"
                    control={control}
                    defaultValue={
                      defaultValues?.storage_provider_config?.provider ??
                      LOCAL_STORAGE_PROVIDER
                    }
                    rules={{
                      required: 'Seleccione un proveedor para continuar',
                    }}
                    render={({ field }) => (
                      <>
                        <RadioOptionWrapper
                          label="Gestión manual"
                          imageSrc={toolsLogo}
                          selected={field.value === LOCAL_STORAGE_PROVIDER} // Pasar propiedad seleccionada
                        >
                          <input
                            type="radio"
                            {...field}
                            value={LOCAL_STORAGE_PROVIDER}
                            checked={field.value === LOCAL_STORAGE_PROVIDER}
                          />
                        </RadioOptionWrapper>

                        <RadioOptionWrapper
                          label="Microsoft OneDrive"
                          imageSrc={oneDriveLogo}
                          selected={field.value === 'onedrive'} // Pasar propiedad seleccionada
                        >
                          <input
                            type="radio"
                            {...field}
                            value="onedrive"
                            checked={field.value === 'onedrive'}
                          />
                        </RadioOptionWrapper>

                        <RadioOptionWrapper
                          label="Google Drive"
                          imageSrc={googleDriveLogo}
                          selected={field.value === 'google_drive'} // Pasar propiedad seleccionada
                        >
                          <input
                            type="radio"
                            {...field}
                            value="google_drive"
                            checked={field.value === 'google_drive'}
                          />
                        </RadioOptionWrapper>

                        <RadioOptionWrapper
                          label="Dropbox"
                          imageSrc={dropboxLogo}
                          selected={field.value === 'dropbox'}
                        >
                          <input
                            type="radio"
                            {...field}
                            value="dropbox"
                            checked={field.value === 'dropbox'}
                          />
                        </RadioOptionWrapper>
                      </>
                    )}
                  />
                </Box>
                {errors.storage_provider_config?.provider && (
                  <FormHelperText error>
                    {errors.storage_provider_config.provider.message}
                  </FormHelperText>
                )}
              </Box>

              <CTA
                size="medium"
                variant="system"
                type="submit"
                isDisabled={
                  !ENABLE_DRIVE_INTEGRATIONS || isKBSyncing || isSubmitting
                }
              >
                {isSubmitting
                  ? 'Guardando...'
                  : mode === 'edit'
                    ? 'Guardar cambios'
                    : 'Crear'}
              </CTA>
            </Stack>
          </form>
        </div>
        <DriveIntegrationExplanations
          provider={
            provider as
              | 'google_drive'
              | 'onedrive'
              | 'dropbox'
              | 'azure_blob_storage'
          }
        />
      </div>
    </TransitionLayout>
  )
}

export const KnowledgeBaseFormPage = observer(() => {
  const navigate = useNavigate()
  const params = useParams()
  const knowledgeBaseId = params.id

  const { knowledgeBases } = rootStore
  const { isLoading, knowledgeBase } = useKnowledgeBaseById(knowledgeBaseId)

  const [isUpdatingKnowledgeBase, setIsUpdatingKnowledgeBase] = useState(false)

  const triggerOAuthFlowStart = async (
    modifiedStorageProviderConfig: StorageProviderConfig,
  ) => {
    const newProvider = modifiedStorageProviderConfig.provider
    const redirectURL = `${window.location.origin}/knowledge-bases/oauth-callback/${newProvider}`

    await knowledgeBases.startOAuthFlow(
      newProvider,
      knowledgeBaseId!!,
      redirectURL,
    )
  }

  const updateKnowledgeBase = async (
    knowledgeBaseId: string,
    updatedStorageProviderConfig: StorageProviderConfig,
  ) => {
    await knowledgeBases.update(knowledgeBaseId, {
      storage_provider_config: updatedStorageProviderConfig,
    })

    navigate(`/knowledge-bases/${knowledgeBaseId}`)

    toast.success('Base de conocimiento actualizada con éxito.')
  }

  // eslint-disable-next-line no-unused-vars
  const handleCreate = async (_data: KnowledgeBaseFormValues) => {
    // NOTE: Creation is not allowed yet
    // 1. Create KB
    // 2. `checkStorageProviderOAuthSupport()`
    // 2. `triggerOAuthFlowStart()`
  }

  const handleEdit = async (data: KnowledgeBaseFormValues) => {
    if (!knowledgeBaseId || !knowledgeBase) {
      // NOTE: This should never happen
      return
    }

    setIsUpdatingKnowledgeBase(true)

    // TODO: Update other KB fields. Not implemented yet.
    const { storage_provider_config: updatedStorageProviderConfig } = data

    const providerChanged =
      knowledgeBase.storage_provider_config.provider !==
      updatedStorageProviderConfig.provider

    if (providerChanged) {
      const acceptsChange = confirm(
        '¿Estás seguro de que deseas cambiar de proveedor de almacenamiento? Se perderán los datos actuales.',
      )

      if (!acceptsChange) {
        setIsUpdatingKnowledgeBase(false)
        return
      }

      // NOTE: Provider doesn't require OAuth
      if (
        !checkStorageProviderOAuthSupport(updatedStorageProviderConfig.provider)
      ) {
        // @ts-ignore - Provider enum works as string
        await updateKnowledgeBase(knowledgeBaseId, updatedStorageProviderConfig)
        setIsUpdatingKnowledgeBase(false)
        return
      }

      // NOTE: Provider changed and requires OAuth
      await triggerOAuthFlowStart(updatedStorageProviderConfig as any)
    } else if (updatedStorageProviderConfig.authorized) {
      // NOTE: Provider stays the same and is already authorized. Nothing to do.
      setIsUpdatingKnowledgeBase(false)
      return
    }
  }

  const onSubmit = async (data: KnowledgeBaseFormValues) => {
    if (!ENABLE_DRIVE_INTEGRATIONS) {
      return
    }

    const creationMode = !knowledgeBaseId

    if (creationMode) {
      // NOTE: Do nothing, creation is not allowed yet
      await handleCreate(data)
    } else {
      await handleEdit(data)
    }
  }

  if (!knowledgeBaseId) {
    return <Navigate to="/404" replace />
  }

  if (isLoading) {
    return (
      <div className="top-loading-bar">
        <LinearProgress />
      </div>
    )
  }

  if (!knowledgeBase) {
    return <div>Knowledge Base not found</div>
  }

  return (
    <KnowledgeBaseForm
      mode="edit"
      onSubmit={onSubmit}
      defaultValues={{
        name: knowledgeBase.name,
        storage_provider_config: {
          provider: knowledgeBase.storage_provider_config.provider as string,
          authorized: !!knowledgeBase.storage_provider_config.authorized,
        },
      }}
      syncStatus={knowledgeBase.sync_status}
      isSubmitting={isUpdatingKnowledgeBase}
    />
  )
})
